import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppContent from "./containers/App/index";
import { useSelector, useDispatch } from "react-redux";

import { getBusinessData } from "./appRedux/actions/Auth";

const App = () => {
  console.log(
    process.env.REACT_APP_Identity_Microservice_URL,
    process.env.NODE_ENV
  );

  const group_id = useSelector(({ auth }) => auth.group_id);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBusinessData({ id: group_id }));
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/" component={AppContent} />
      </Switch>
    </Router>
  );
};

export default App;
